define("discourse/plugins/discourse-gamification/discourse/models/gamification-leaderboard", ["exports", "@glimmer/tracking", "I18n", "discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard"], function (_exports, _tracking, _I18n, _gamificationLeaderboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GamificationLeaderboard = _exports.default = (_class = class GamificationLeaderboard {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new GamificationLeaderboard(args);
    }
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "createdAt", _descriptor2, this);
      _initializerDefineProperty(this, "updatedAt", _descriptor3, this);
      _initializerDefineProperty(this, "createdById", _descriptor4, this);
      _initializerDefineProperty(this, "excludedGroupsIds", _descriptor5, this);
      _initializerDefineProperty(this, "includedGroupsIds", _descriptor6, this);
      _initializerDefineProperty(this, "visibleToGroupsIds", _descriptor7, this);
      _initializerDefineProperty(this, "forCategoryId", _descriptor8, this);
      _initializerDefineProperty(this, "fromDate", _descriptor9, this);
      _initializerDefineProperty(this, "toDate", _descriptor10, this);
      _initializerDefineProperty(this, "name", _descriptor11, this);
      _initializerDefineProperty(this, "period", _descriptor12, this);
      this.id = args.id;
      this.createdAt = args.created_at;
      this.updatedAt = args.updated_at;
      this.createdById = args.created_by_id;
      this.excludedGroupsIds = args.excluded_groups_ids;
      this.includedGroupsIds = args.included_groups_ids;
      this.visibleToGroupsIds = args.visible_to_groups_ids;
      this.forCategoryId = args.for_category_id;
      this.fromDate = args.from_date;
      this.toDate = args.to_date;
      this.name = args.name;
      this.period = args.period;
      if (Number.isInteger(args.default_period)) {
        this.defaultPeriod = _I18n.default.t(`gamification.leaderboard.period.${_gamificationLeaderboard.LEADERBOARD_PERIODS[args.default_period]}`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "createdById", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "excludedGroupsIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "includedGroupsIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "visibleToGroupsIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "forCategoryId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fromDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "toDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "period", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});